<template>
  <div>
    <div class="cover" v-if="showCover">
      <div class="time">{{time}}</div>
      <img :src="require('@/assets/img/save/cover.png')" alt="">
    </div>
    <div class="save-code" v-else>
      <div class="title-content">
        <img :src="require('@/assets/img/save/code.png')" alt="" class="code-img">
        <span class="title">房东网格</span>
      </div>
      <div class="house-content">
        <div class="house-name">{{ houseName }}</div>
        <div>
          <div class="btn" @click="toLogin">登录</div>
          <div class="btn btn1" @click="toRegister">自主登记</div>
        </div>
      </div>
      <div class="user-info">
        <div class="user-info-title">{{ managerNum }}员信息</div>
        <div style="display: flex; justify-content: space-between; flex-wrap: wrap">
          <div class="user-info-item" v-for="item in userInfoList">
            <img :src="item.avatar" alt="">
            <div>
              <div class="post">{{ item.workName }}</div>
              <div class="name">{{ item.name }}</div>
              <div class="mobile">{{ getMobile(item.mobile) }}</div>
            </div>
          </div>

        </div>
      </div>
      <div class="notice-info">
        <van-row style="width: 100%">
          <van-col span="4">
            <div class="notice-icon">
              <div class="xx">消息</div>
              <div class="gg">公告</div>
            </div>
          </van-col>
          <van-col span="18">
            <div class="notice-content" @click="noticeList[0] ? getInfo(noticeList[0].id) : ''">
<!--              <div style="color: #7B90E5; white-space: nowrap">置顶</div>-->
              <div class="notice-title">{{noticeList[0] ? noticeList[0].title : ''}}</div>
            </div>
            <div class="notice-content" style="margin-top: 4px;" @click="noticeList[1] ? getInfo(noticeList[1].id) : ''">
<!--              <div style="color: #7ECBBD; white-space: nowrap">最新</div>-->
              <div class="notice-title">{{noticeList[1] ? noticeList[1].title : ''}}</div>
            </div>
          </van-col>
          <van-col span="2">
            <div style="width: 100%; text-align: right">
              <van-icon style="margin-top: 4px;" size="14" name="arrow" color="#787E9A" @click="goNotice()" />
            </div>
          </van-col>
        </van-row>
      </div>
      <div class="enter">
        <div class="left" @click="goProblem()">
          <div class="title">随手报<van-icon style="margin-left: 18px;" size="18" name="arrow" color="#A45B00" /></div>
          <div class="des">社区问题随手拍，即刻反馈马上办</div>
          <div class="icon"></div>
        </div>
        <div class="right">
          <div class="top" @click="goImprove()">
            <div class="title">自主整改<van-icon style="margin-left: 12px;" size="14" name="arrow" color="#192558" /></div>
            <div class="des">巡查问题自主办，整改到位不拖延</div>
            <div class="icon"></div>
          </div>
          <div class="bottom" @click="goHelp()">
            <div class="title">我要求助<van-icon style="margin-left: 12px;" size="14" name="arrow" color="#BD2C47" @click="goNotice()" /></div>
            <div class="des">消防求助一键达，极速救援护平安</div>
            <div class="icon"></div>
          </div>
        </div>
      </div>
      <div class="ad">
        <div class="top">
          <div style="display: flex; align-items: end ">
            <div class="title">平安宣传</div>
            <div class="des">今日安全提醒~</div>
          </div>

          <div class="more" @click="goNoticeSafe()">全部<van-icon style="margin-left: 7px;" size="14" name="arrow" color="#787E9A" /></div>
        </div>
        <van-swipe @change="onChange" indicator-color="#6A78B2" :autoplay="3000">
          <van-swipe-item v-for="item in Math.ceil(noticeListSafe.length / 3)" :key="item">
            <div v-for="(i, index) in noticeListSafe.slice((item - 1) * 3, (item - 1) * 3 + 3)" class="list-item" @click="getInfo(i.id)">
              <div class="icon" :style="{'background': index == 0 ? '#69BEFF': index == 1 ? '#FFCB8D' : '#FFBBC8'}"></div>
              <div class="text">{{i.title}}</div>
<!--              <div class="hot">热</div>-->
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div id="mapDiv" v-show="false"></div>
    <van-dialog
      v-model="showVerify"
      cancel-button-text="取消"
      confirm-button-text="确定"
      show-confirm-button
      show-cancel-button
      @confirm="verifyConfirm"
      @cancel="showVerify = false">
      <div class="verify">
        <van-field name="联系电话" label="联系电话" placeholder="请输入联系电话" v-model="dataForm.mobile"></van-field>
        <van-field name="短信验证码" label="短信验证码" placeholder="请输入验证码" v-model="dataForm.code">
          <template #button>
            <van-button v-if="btnSendText == '发送验证码'" size="small" type="info" @click="getCode" style="border: none">{{btnSendText}}</van-button>
            <div v-else class="btnSendText">{{btnSendText}}</div>
          </template>
        </van-field>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import { getImageStream } from '@/utils/index'
import uploadFile from '@/components/upload/uploadFile'
import { ImagePreview } from 'vant';
export default {
  data() {
    return {
      showCover: false,
      time: 3,
      timer: null,
      openid:'',
      unionid:'',
      mobile:'',
      noticeListSafe: [],
      houseName: '',
      detailHouseName: '',
      houseId: '',
      managerNum: '',
      orgId: '',
      safeImg:'',
      noticeType:'',
      subareaId:'',
      noticeList: [],
      userInfoList: [],
      buildingId:'',
      url:'https://sgg.hzxh.gov.cn/police-api',
      // url:'http://192.168.0.108:8091',
      //管理员跳转到网格果果
      // adminurl:'http://192.168.0.76:8080/adminCode',
      type: 1, //1是房屋id,2是楼幢id
      id: '',
      current: 0,
      showVerify: false,
      dataForm: {
        mobile: '',
        code: ''
      },
      btnSendText: '发送验证码',
      btnSendChecked: false,
      timerCode: null
    }
  },
  components: {
    uploadFile
  },
  destroyed() {
    clearInterval(this.timer)
    this.timer = null
    clearInterval(this.timerCode)
    this.timerCode = null
  },
  created() {
    if (this.$route.query.domain && this.$route.query.domain != 'null'&&!this.$route.query.p&&!this.$route.query.u){
      this.$http.changeUrl(this.$route.query.domain, true)
      this.$httpApp.changeUrl(this.$route.query.domain, true)
      this.$httpCustApp.changeUrl(this.$route.query.domain, true)
    }
    this.showCover = sessionStorage.getItem('showCover') !== '0'
    if (this.showCover) {
      setTimeout(() => {
        this.showCover = false
        clearInterval(this.timer)
        this.timer = null
        sessionStorage.setItem('showCover', '0')
      }, 3000)
      this.time = 3
      this.timer = setInterval(() => {
        this.time -= 1
      }, 1000)
    }
    if (this.$route.query.params) {
      Vue.prototype.$appid = 'wxfa4ce77dc91e7327'
      sessionStorage.setItem('appid', 'wxfa4ce77dc91e7327')
      this.openid = this.$route.query.p
      this.unionid = this.$route.query.u
      this.mobile = this.$route.query.m
      this.params = this.$route.query.params
      this.domain = this.$route.query.domain
      this.$nextTick(() => {
        if (!this.openid && !this.unionid){
          // if(this.isWeixinBrowser()) {
          //   如果是微信内部浏览器就打开小程序
          const param = `params=${this.$route.query.params}` + `&domain=${this.$route.query.domain}` + `&code=safet2ycode`
          this.$http({
            url: this.$http.adornUrl('/wxapp/getWxUrlScheme'),
            method: 'post',
            params: this.$http.adornParams({
              param
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              location.href =data.wxUrlScheme

            }
          })
          // }
        }
      })
    }
    this.getScanCodeParams()

  },
  methods: {
    onChange(index) {
      this.current = index;
    },
    getInfo(id) {
      this.$store.commit('setNoticeId', id)
      this.$router.push('/notice-info')
    },
    goNoticeSafe() {
      let tmp1=this.orgId|| Vue.prototype.$globalData.userInfo.orgId
      let tmp2=this.noticeType
      this.$router.push({path:'/notice',query:{orgId:tmp1,noticeType:tmp2}})
    },
    goNotice() {
      let tmp1=this.orgId|| Vue.prototype.$globalData.userInfo.orgId
      let tmp2=1
      this.$router.push({path:'/notice',query:{orgId:tmp1,isOpen:tmp2}})
    },
    goProblem() {
      this.$router.push({path:'/saveCode-problem', query: {orgId: this.orgId, address: this.detailHouseName, buildingId: this.buildingId, houseId: this.houseId}})
    },
    goHelp() {
      this.$router.push({path:'/saveCode-help', query: {orgId: this.orgId, address: this.detailHouseName, buildingId: this.buildingId, houseId: this.houseId}})
    },
    goImprove () {
      if (!sessionStorage.getItem('userMobile')) {
        this.dataForm.mobile = ''
        this.dataForm.code = ''
        this.showVerify = true
      } else {
        this.$router.push({path:'/saveCode-problem-list', query: {
          buildingId: this.buildingId,
          houseId: this.houseId,
          mobile: sessionStorage.getItem('userMobile'),
          placeType: this.houseId ? 2 : 1,
          orgId: this.orgId
        }})
      }
    },
    verifyConfirm () {
      if (!this.dataForm.mobile) {
        return this.$toast.fail('请输入联系电话')
      } else if (!this.btnSendChecked) {
        return this.$toast.fail('请先获取验证码')
      } else if (!this.dataForm.code) {
        return this.$toast.fail('请输入验证码')
      }
      this.$httpCustApp({
        url: 'https://api.hzuht.com/general/verify/verifyCode',
        method: 'post',
        params: this.$httpCustApp.adornParams({
          token: this.dataForm.mobile,
          code: this.dataForm.code
        })
      }).then(({ data }) => {
        if (data && data.code === 0){
          sessionStorage.setItem('userMobile', this.dataForm.mobile)
          sessionStorage.setItem('code', this.dataForm.code)
          this.$router.push({path:'/saveCode-problem-list', query: {
            buildingId: this.buildingId,
            houseId: this.houseId,
            mobile: this.dataForm.mobile,
            placeType: this.houseId ? 2 : 1,
            orgId: this.orgId
          }})
        }
      })
    },
    getCode () {
      if (!this.dataForm.mobile) {
        return this.$toast.fail('请输入联系电话')
      } else if (this.btnSendText != '发送验证码') {
        return
      }
      this.$httpCustApp({
        url: 'https://api.hzuht.com/general/verify/sendVerifyCode',
        method: 'post',
        params: this.$httpCustApp.adornParams({
          token: this.dataForm.mobile,
          mobile: this.dataForm.mobile,
          needExist: 1
        })
      }).then(({ data }) => {
        if (data && data.code === 0){
          this.$toast.success ({
            message: '发送成功',
            duration: 1500,
          })
          this.countDown()
          // this.dataForm.code = data.code
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    countDown () {
      let that = this;
      let countDownNum = 60
      this.timerCode = setInterval( () => {
        countDownNum--;
        that.btnSendText = countDownNum
        that.btnSendChecked = true
        if (countDownNum == 0) {
          clearInterval(that.timerCode)
          that.timerCode = null
          that.btnSendText = '发送验证码'
        }
      }, 1000)
    },
    getMobile(value) {
      let result = [];
      for (let i = 0; i < value.length; i++) {
        if (i == 3 || i == 7) {
          result.push(" " + value.charAt(i));
        } else {
          result.push(value.charAt(i));
        }
      }
      return result.join('')
    },
    numChange(i) {
      let arry = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
      if (i <= 10) {
        return arry[i];
      }
      if (i % 10 == 0 && i < 100) {
        return arry[Math.floor(i / 10)] + "十";
      }
      if (i > 10 && i < 20) {
        return "十" + this.numChange(i - 10 * Math.floor(i / 10));
      }
      if (i > 20 && i < 100 && i % 10 != 0) {
        return arry[Math.floor(i / 10)] + "十" + numChange(i - 10 * Math.floor(i / 10));
      }
    },
    getScanCodeParams() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/scanCode/getScanCodeParams'),
        method: 'get',
        params: this.$http.adornParams({
          id: this.$route.query.params|| Vue.prototype.$qrcodeOptions
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          let houseNameList = data.data.houseName.split('-')
          this.houseId = data.data.houseId
          this.id = this.houseId
          this.buildingId = data.data.buildingId
          if (this.buildingId){
            this.type = 2
            this.id = this.buildingId
          }
          this.orgId = data.data.orgId
          this.detailHouseName = data.data.houseName
          this.houseName = houseNameList.splice(1, houseNameList.length - 1).join('-')
          if((this.unionid||Vue.prototype.$unionid)&&(this.openid||Vue.prototype.$openid)){
            this.isLogin()
          }
          this.getManager()
          this.getNoticeType()
          this.getNotice()
          this.getImg()
          if (this.orgId) {
            this.getStreetId(this.orgId)
          }
        }
      })
    },
    isLogin(){
      this.$http({
        url: this.$http.adornUrl(`/wxapp/scanCode/isLogin`),
        method: 'get',
        params: this.$http.adornParams({
          unionId: this.unionid||Vue.prototype.$unionid,
          openId:this.openid||Vue.prototype.$openid,
          orgId:this.orgId,
          houseId: this.houseId||''
        })
      }).then(({data}) => {
        if (data.code == 0 && data) {

          if(this.buildingId&&data.isAdmin==true){
            sessionStorage.setItem('token',data.token)
            this.$cookies.set('token',data.token)
            this.$router.replace({path:'/AdminCode',query:{buildingId:this.buildingId||Vue.prototype.$buildingId,mobile:this.mobile|| Vue.prototype.$purePhoneNumber,
                data:this.$route.query.params|| Vue.prototype.$qrcodeOptions,orgId:this.orgId||
                    Vue.prototype.$globalData.userInfo.orgId
                ,userId:data.userId,assistId:data.assistId}})
            return;
          }
          else if(data.isAdmin==false&&data.isLive==false){
            return
          }
          if(this.houseId&&(data.isAdmin==true||data.isLive==true)) {
            sessionStorage.setItem('token',data.token)
            this.$cookies.set('token',data.token)
            this.$route.query.userId=data.userId
            this.$router.replace({
              path: '/userReal',
              query:this.$route.query
            })
          }

          // this.subareaId = data.buildingInfo.subarea
          // this.$route.query.type = 'zuke'
          // this.$route.query.subareaId = this.subareaId
          // this.$route.query.buildingId = this.buildingId
          // this.$router.push({ path: '/sso', query: this.$route.query})
        }
      })
    },
    getImg() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/building/info/getFireImg`),
        method: 'get',
        params: this.$http.adornParams({
          id: this.id,
          type: this.type
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          // this.safeImg=[{fileType:'image',relativePath:data.info.fireSafetyImg}]
          this.safeImg=data.info.fireSafetyImg?getImageStream(data.info.fireSafetyImg):''
          // this.safeImg=''
        }
      })
    },
    imgPreView (path) {
      ImagePreview({
        images: [path],
        startPosition: 0,
        closeable: true,
      });
    },
    getNotice() {
      this.$httpApp({
        url: this.$httpApp.adornUrl(`/wxapp/commonApp/notice/listWithoutToken`),
        method: 'post',
        params: this.$httpApp.adornParams({
          page: 1,
          limit: 2,
          logInOrgId: this.orgId|| Vue.prototype.$globalData.userInfo.orgId,
          orgUserId: 0,
          isOpen: 1
        })
      }).then(({ data }) => {
        if (data && data.code == 0) {
          this.noticeList=data.page.list
        }
      })
    },
    getNoticeType() {
      this.$http({
        url: this.$http.adornUrl(`/sys/dict/listDictByCode`),
        method: 'get',
        params: this.$http.adornParams({
          code: 'noticeType',
          orgId: this.orgId|| Vue.prototype.$globalData.userInfo.orgId
        })
      }).then(({ data }) => {
        if (data && data.code == 0) {
          data.dicts.forEach(item => {
            if (item.label === '平安宣传' && item.parameters.type === 'safetyCode') {
              this.noticeType = item.value
            }
          })
          this.getNoticeSafe()
        }
      })
    },
    getNoticeSafe() {
      this.$httpApp({
        url:this.$httpApp.adornUrl( `/wxapp/commonApp/notice/listWithoutToken`),
        method: 'post',
        params: this.$httpApp.adornParams({
          page: 1,
          limit: 9,
          logInOrgId: this.orgId|| Vue.prototype.$globalData.userInfo.orgId,
          orgUserId: 0,
          noticeType: this.noticeType
        })
      }).then(({ data }) => {
        if (data && data.code == 0) {
          this.noticeListSafe=data.page.list
        }
      })
    },
    getManager() {
      this.$httpApp({
        url: this.$httpApp.adornUrl( `/building/manager/info/getManager/${this.id}`),
        method: 'get',
        params: this.$httpApp.adornParams({
          type: this.type
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          let tmp = data.managers
          data.managers.map((item, index) => {
            tmp[index].avatar = getImageStream(item.avatar)
          })
          this.userInfoList = tmp
          this.managerNum = this.numChange(data.managers.length)
        }
      })
    },
    // 根据社区id获取街道id
    getStreetId(id) {
      this.$http({
        url: this.$http.adornUrl(`/sys/org/noLogin/info/${id}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data.code == 0 && data) {
          let street = data.sysOrg.path.split(',')
          let newStreet = street.splice(0, street.length - 1)
          this.streetPath = newStreet.slice(1).toString()
        }
      })
    },
    // 根据楼幢id获取小区楼幢
    getBuildingId(id) {
      this.$http({
        url: this.$http.adornUrl(`/building/info/noLogin/info/${id}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data.code == 0 && data) {
          this.subareaId = data.buildingInfo.subarea
          this.$route.query.type = 'zuke'
          this.$route.query.subareaId = this.subareaId
          this.$route.query.buildingId = this.buildingId
          this.$router.push({ path: '/newRegister', query: this.$route.query})
        }
      })
    },
    toLogin() {
      //+ `&path=${encodeURIComponent('pages/login/login')}`
      const param = `params=${this.$route.query.params||Vue.prototype.$qrcodeOptions}` + `&domain=${this.$route.query.domain||  Vue.prototype.$globalData.domain}`+ `&streetId=${this.streetPath||  Vue.prototype.$globalData.userInfo.street}`
      this.$http({
        url: this.$http.adornUrl('/wxapp/getWxUrlScheme'),
        method: 'post',
        params: this.$http.adornParams({
          param,
          path: '/pages/login/login'
        })
      }).then(({ data }) => {
        if (data && data.code === 0){
          location.href = data.wxUrlScheme
        }
      })
    },
    toRegister() {
      if (this.buildingId){
        this.getBuildingId(this.buildingId)
      }else if (this.houseId){
        console.log( this.$route.query)
        this.$route.query.type = 'zuke'
        this.$router.push({ path: '/newRegister', query: this.$route.query })
      } else {
        this.$message.error("页面加载未完成,请稍后再试!")
      }
    }
  }
}
</script>
<style scoped lang="scss">
.cover {
  width: 100vw;
  .time {
    font-family: DINPro-Bold;
    font-size: 24px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border: 2px solid #fff;
    border-radius: 50%;
    color: #fff;
    position: fixed;
    top: 30px;
    right: 30px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
.save-code {
  font-family: PingFangSC-Regular;
  overflow: auto;
  min-height: 100vh;
  width: 100vw;
  background: linear-gradient( 180deg, #6EC2FF 0%, #AFDAFE 15%, #ECF3FF 30%, #F3F6FA 100%);
  //background-image: url("~@/assets/img/save/saveCode-bg2.png");
  //background-size: 750px 560px;
  //background-repeat: no-repeat;
  padding-bottom: 24px;

  .title-content {
    margin: 54px auto 0;
    width: 690px;
    text-align: center;
    position: relative;
    height: 48px;
    line-height: 48px;

    .code-img {
      width: 52px;
      height: 52px;
      position: absolute;
      left: 0;
    }

    .title {
      font-size: 34px;
      color: #fff;
    }
  }

  .house-content {
    width: 690px;
    height: 94px;
    margin: 144px auto 0;
    background: rgba(255,255,255,0.84);
    border-radius: 12px 12px 0px 0px;
    border: 1px solid rgba(54,141,211,0.44);
    //background-image: url("~@/assets/img/save/saveCode-cube.png");
    //background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;

    .house-name {
      font-size: 24px;
      color: #8C95B7;
    }

    .btn {
      display: inline-block;
      margin-right: 24px;
      //width: 136px;
      line-height: 46px;
      text-align: center;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0);
      border: 1px solid #27A3FF;
      font-size: 24px;
      color: #1C9EFF;
      padding: 6px 12px;
    }

    .btn1 {
      background: #41AFFF;
      border: 1px solid #5992F8;
      color: #fff;
    }
  }

  .content-title {
    height: 88px;
    color: #333;
    font-size: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .user-info {
    width: 690px;
    margin: 0 auto;
    padding: 30px 22px 20px;
    background: linear-gradient( 180deg, #CAE6FF 0%, #FDFFFF 100%);
    box-shadow: 0px 8px 8px 0px #ECF0F7;
    border-radius: 0px 0px 16px 16px;

    .user-info-title {
      line-height: 44px;
      color: #192558;
      font-size: 32px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 18px;
    }

    .user-info-item {
      width: 316px;
      height: 208px;
      background: linear-gradient( 180deg, #F0F9FF 0%, #FDFFFF 100%);
      display: flex;
      padding: 44px 20px;
      margin-bottom: 10px;
      border-radius: 16px;
      img {
        width: 120px;
        height: 120px;
        margin-right: 12px;
      }

      .post {
        font-size: 28px;
        color: #333;
        line-height: 36px;
        margin-top: 4px;
      }

      .name {
        font-size: 24px;
        color: #333;
        line-height: 36px;
        margin-top: 4px;
      }

      .mobile {
        font-size: 20px;
        color: #929AB9;
        line-height: 34px;
        margin-top: 4px;
      }
    }
  }

  .notice-info {
    width: 690px;
    height: 116px;
    margin: 32px 30px 0;
    background: #fff;
    border-radius: 16px;
    padding: 20px;
    box-shadow: 0px 8px 8px 0px rgba(220,220,220,0.25);
    display: flex;
    align-items: center;
    .notice-icon {
      width: 76px;
      height: 80px;
      background: #F6F9FF;
      border-radius: 12px;
      border: 1px solid #D1D9FB;
      padding: 8px 6px 6px 6px;
      text-align: center;
      .xx {
        font-family: 'MF MoDeng';
        font-size: 28px;
        color: #5566AE;
      }
      .gg {
        font-family: 'MF MoDeng';
        font-size: 28px;
        color: #3F3F3F;
      }
    }
    .notice-content {
      font-family: PingFangSC-Regular;
      display: flex;
      font-size: 22px;
      line-height: 36px;
      width: 100%;

      .notice-title {
        margin-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .enter {
    width: 690px;
    margin: 32px auto 0;
    display: flex;
    justify-content: space-between;
    font-family: PingFangSC-Regular;
    .left {
      width: 330px;
      height: 288px;
      background: linear-gradient( 179deg, #FFE8CD 0%, #FFF7EE 100%);
      border-radius: 16px;
      padding: 24px;
      position: relative;
      .title {
        color: #A45B00;
        font-size: 48px;
        line-height: 56px;
      }
      .des {
        margin-top: 12px;
        color: #D8A86C;
        line-height: 32px;
        font-size: 28px;
        z-index: 999;
        position: relative;
      }
      .icon {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 208px;
        height: 188px;
        background: linear-gradient( 180deg, #FFE2C1 0%, #FFF4E8 100%);
        border-radius: 50%;
        transform: rotate(-15deg);
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .top {
        width: 330px;
        height: 136px;
        background: linear-gradient( 180deg, #B6DFFF 0%, #E7F4FF 100%);
        border-radius: 8px;
        padding: 16px 24px;
        position: relative;
        .title {
          color: #192558;
          font-size: 32px;
          line-height: 38px;
        }
        .des {
          margin-top: 6px;
          color: #7B819C;
          font-size: 24px;
          line-height: 32px;
          z-index: 999;
          position: relative;
        }
        .icon {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 106px;
          height: 106px;
          background: linear-gradient( 176deg, #B6DFFF 0%, #C8E7FF 30%, #E7F4FF 100%);
          border-radius: 50%;
          transform: rotate(-20deg);
        }
      }
      .bottom {
        width: 330px;
        height: 136px;
        background: linear-gradient( 180deg, #FFDCE3 0%, #FFEFF2 100%);
        border-radius: 8px;
        padding: 16px 24px;
        position: relative;
        .title {
          color: #BD2C47;
          font-size: 32px;
          line-height: 38px;
        }
        .des {
          margin-top: 6px;
          color: #F6AAB9;
          font-size: 24px;
          line-height: 32px;
          z-index: 999;
          position: relative;
        }
        .icon {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 106px;
          height: 106px;
          background: linear-gradient( 181deg, #FFDCE3 0%, #FFF1F2 100%);
          border-radius: 50%;
          transform: rotate(-20deg);
        }
      }
    }
  }

  .ad {
    width: 690px;
    margin: 34px auto;
    height: 286px;
    box-shadow: 0px 8px 8px 0px #EBECEE;
    border-radius: 22px;
    background-color: #fff;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 22px 22px 0 0;
      background: linear-gradient( 360deg, #fff 0%, #AFDCFF 100%);
      padding: 24px;
      .title {
        font-weight: 600;
        font-size: 32px;
        color: #192558;
      }
      .des {
        font-size: 26px;
        color: #22337C;
        margin-left: 18px;
      }
      .more {
        float: right;
        font-size: 24px;
        color: #787E9A;
        display: flex;
        align-items: center;
      }
    }
    .list-item {
      display: flex;
      align-items: center;
      padding: 0 26px;
      height: 48px;
      line-height: 48px;
    }
    .icon {
      width: 20px;
      height: 20px;
      background: #69BEFF;
      border-radius: 50%;
    }
    .text {
      max-width: 540px;
      margin-left: 12px;
      font-size: 24px;
      color: #7B829C;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .hot {
      width: 40px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background: #FFE479;
      color: #22337C;
      font-size: 20px;
      border-radius: 10px;
      margin-left: 14px;
    }
  }
}
.van-swipe {
  height: 212px;
}
.verify {
  padding-top: 30px;
  ::v-deep .van-field__label {
    display: flex;
    align-items: center;
  }
}
.btnSendText {
  color: #1989fa;
  margin-right: 40px;
}
</style>
